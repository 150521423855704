import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';
export default function ApproveDialog({open, toggle, schedId , reload, setReload, setApproverResponse, setSeverity, setSnackOpen, setAppDisabled}) {
  const [isLoading, setLoading] = useState(false);
      
      const acknowledgeSched = async (rowData) => {
        setLoading(true);
        const access_token = localStorage.getItem('access_token');
        const config = {
            method: "POST",
            headers: {
                Authorization: "Bearer " + access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ schedId, tp: process.env.REACT_APP_TP}),
        };
        const response = await fetch(
            'https://etd-means.aboitizpower.com/means/acknowledge_test', config
        );
        const data = await response.json();
        if (data.message == "Transaction Completed") {
            setLoading(false)
            setApproverResponse("Success");
            setSeverity("success");
            setSnackOpen(true);
            setReload(!reload)
            toggle(false);
            setAppDisabled(false)
        } else {
            setLoading(false)
            setApproverResponse("Failed");
            setSeverity("error");
            setSnackOpen(true);
            setReload(!reload)
            toggle(false);
            setAppDisabled(false)
        }
        setAppDisabled(false)
        // setSubmit(!isSubmit);
    };
    const closeDialog = () => {
      setReload(!reload)
      toggle(false);
    }
  return (
    <> 
      <Dialog
        open={open}
        onClose={() => toggle(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Acknowledge: {schedId} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            No
          </Button>
          <Button enabled={!isLoading} onClick={acknowledgeSched} color="primary" autoFocus>
            {isLoading ? <CircularProgress  size={10}/> : "OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}