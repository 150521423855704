import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Divider from '@material-ui/core/Divider';
import StarBorder from "@material-ui/icons/StarBorder";
import moment from "moment";
import { Box } from "@material-ui/core";
var fileDownload = require('js-file-download');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function StatusList({
  date_time,
  user_name,
  log_file,
  log_id,
  status,
  note,
  reload
}) {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const downloadFile = async (file_path) => {
    let file_name = file_path.split("/").pop(); 
    const access_token = localStorage.getItem("access_token"); 
    
    const config = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({file_id: log_id, tp: process.env.REACT_APP_TP}), 
    };

    const response = await fetch(`https://etd-means.aboitizpower.com/means/download`, config);
    const data = await response.text();
    
    fileDownload(data, file_name);
  }; 
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<>File: <b>{log_file}</b> </>}
          secondary={"Status: " + status.toUpperCase()}
        />
        {open ? <ExpandLess /> : <ExpandMore />}

        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => downloadFile(log_file)} download>
            <GetAppIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider light />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding> 
            <Box
             display="flex"
             flexDirection="column"
             p={1}
             ml={5}
             alignItems="left"
             justifyContent="space-around">
            <Typography variant="h9"><b>User: </b> {user_name}</Typography> 
            <Typography variant="h9"></Typography>
          {status == "rejected" ? ( 
              <Typography variant="h9"><b>Note: </b> {note.toUpperCase()}</Typography>
          ) : null}
            </Box>
        
        </List>
      </Collapse>
    </>
  );
}
