import React, { useCallback, useState, useEffect } from "react";
import {
  Paper,
  Grid,
  makeStyles,
  Box,
  ListItem,
  ListItemText,
  List,
  Avatar,
  Button,
  Typography,
  Fab,
  Snackbar,
  Card,
  CardContent,
  CardActions
} from "@material-ui/core";
import StatusCard from "../components/StatusCard";
import SpeedDial from "@material-ui/lab/SpeedDial";
import StatusList from "../components/StatusList";
import DashboardTable from "../components/DashboardTable";
import CustomerTable from "../components/CustomerTable";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CreateIcon from '@material-ui/icons/Create';
import Backdrop from "@material-ui/core/Backdrop";
import InputDialog from "../dialogs/InputDialog";
import FileDialog from "../dialogs/FileUploadDialog";
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from "@material-ui/lab/Alert";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import TableViewIcon from '@material-ui/icons/TableChart';
var fileDownload = require('js-file-download');

const useStyles = makeStyles((theme) => ({
  Paper: {
    padding: 40,
    borderRadius: 10,
    minHeight: 420,
    maxHeight: 420
  },
  CardContent: {
    minHeight: 275,
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    margin: theme.spacing.unit,
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3
  },
  backdrop: {
    zIndex: 100,
    color: "#fff",
  },
  list: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 255,
  },
}));

const AnalystScreen = () => {
  const [scheds, getScheds] = useState();
  const classes = useStyles();
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const [isInputDialogOpen, toggleInputDialog] = useState(false);
  const [isFileDialogOpen, toggleFileDialog] = useState(false);
  const [severity, setSeverity] = useState('');
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [uploadResponse, setUploadResponse] = useState('');
  const [reload, setReload] = useState(false);
  const [regionId, setRegionId] = useState(1);
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const snackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  let isMounted = true;
  const getData = useCallback(async () => {
    let customer = localStorage.getItem("userCust");
    console.log("customer: " + customer)
    const access_token = localStorage.getItem("access_token");
    const config = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customer: customer, tp: process.env.REACT_APP_TP }),
    };
    const response = await fetch("https://etd-means.aboitizpower.com/means/schedsAnalyst", config);
    const data = await response.json();

    getScheds(data.response);
  });
  const downloadTemplate = useCallback(async () => {
    const access_token = localStorage.getItem("access_token");
    const config = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
        'Content-Type': 'application/json'
      },
    };

    const response = await fetch("https://etd-means.aboitizpower.com/means/downloadFormat", config);
    const data = await response.text();
    fileDownload(data, "Nomination_Template.csv");
    // console.clear();
  });
  const downloadManual = useCallback(async () => {

    const access_token = localStorage.getItem("access_token");
    const config = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
        'Content-Type': 'application/json'
      },
    };

    const response = await fetch("https://etd-means.aboitizpower.com/means/downloadManual", config);
    const data = await response.blob();
    fileDownload(data, "User_Mandual.pdf");
    // console.clear();
  });
  const getRegionId = async () => {
    let customer = localStorage.getItem("userCust");
    const access_token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: "Bearer " + access_token,
        'Content-Type': 'application/json'
      },
    };

    const response = await fetch(`https://etd-means.aboitizpower.com/means/customerRegion/${customer}`, config);
    const data = (await response.json()).data[0].region;
    setRegionId(data)
  }
  const downloadMindanaoTemplate = useCallback(async () => {

    const access_token = localStorage.getItem("access_token");
    const config = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
        'Content-Type': 'application/json'
      },
    };

    const response = await fetch("https://etd-means.aboitizpower.com/means/downloadMindanaoTemplate", config);
    const data = await response.blob();
    fileDownload(data, "MEANS_TEMPLATE_MINDANAO.zip");
    // console.clear();
  });
useEffect(()=>{
  getRegionId();
},[])
  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 30000);
    return () => {

      clearInterval(interval);
    }



  }, [reload])
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Box m={3}>
            <Paper className={classes.Paper}>
              <Typography variant="h4" color="secondary">
                <b>
                  Analyst
                </b>
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                p={1}
                ml={1}
                mr={1}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Box p={5}>
                  <Avatar
                    src={localStorage.getItem("userPhoto")}
                    className={classes.avatar}
                  />
                </Box>
                <Box p={2} display="flex" flexDirection="column">
                  <Typography variant="h1">
                    {localStorage.getItem("userName")}
                  </Typography>
                  <Typography variant="h3">
                    {localStorage.getItem("userEmail")}
                  </Typography>
                  <Typography variant="h3">
                    {localStorage.getItem("userCust")}
                  </Typography> 
                    <Box m={1}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        aria-label="User Guide"
                        onClick={() => downloadManual()}
                      >
                        <PictureAsPdfIcon className={classes.extendedIcon} />
                        User Guide
                      </Button>

                    </Box>
                    <Box hidden={regionId == 2} disabled={regionId == 2} m={1}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        aria-label="Nomination Template"
                        onClick={() => downloadTemplate()}
                      >
                        <TableViewIcon className={classes.extendedIcon} />
                        Nomination Template
                      </Button>

                    </Box>
                   
                        <Box hidden={regionId == 1} disabled={regionId == 1} m={1}>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            aria-label="Nomination Template"
                            onClick={() => downloadMindanaoTemplate()}
                          >
                            <TableViewIcon className={classes.extendedIcon} />
                            Mindanao Template
                          </Button>
 
                  </Box>
                </Box>

              </Box>
              {/* <h1>Submit</h1>
          <Box
            display="flex"
            flexDirection="row"
            p={1}
            m={1}
            alignItems="center"
            justifyContent="space-around"
          >
            <Button variant="contained" >CSV</Button>
            <Button variant="contained" >MANUAL</Button>
          </Box> */}
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Box m={3}>
            <Card className={classes.Paper}>
              <CardContent className={classes.CardContent}>
                <Typography variant="h4" color="secondary">
                  <b>
                    Pending Submissions
                  </b>
                </Typography>
                <List className={classes.list}>
                  {scheds ? (
                    scheds.map((row) => (
                      <StatusList
                        setReload={setReload}
                        reload={reload}
                        date_time={row.datetime_submitted}
                        user_name={row.user_name}
                        log_file={row.file_name}
                        status={row.status}
                        note={row.note}
                        log_id={row.idsubmissions}
                      />
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary={"EMPTY"} />
                    </ListItem>
                  )}
                </List>
              </CardContent>
              <CardActions>
              </CardActions>
              <i>Note: The timestamp under column "Date Approved" represents the acknowledgement of the receipt of the nomination. The delivery shall still follow the relevant provision of the agreed protocol.</i>

            </Card>

          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Box display="flex"
            flexDirection="column"
            ml={5} 
            mr={5}>
            <Box mb={3}>
              <CustomerTable />
            </Box>
            <Box mb={1}> 
              <DashboardTable />
            </Box>
            <Box>
              Copyright<span></span> <span dangerouslySetInnerHTML={{ "__html": "&copy;" }} /> <span></span> 2022. Energy Trading. - All Rights Reserved.
            </Box>
          </Box>
        </Grid>

      </Grid>
      {/* <InputDialog open={isInputDialogOpen} toggle={toggleInputDialog} /> */}
      <FileDialog open={isFileDialogOpen} toggle={toggleFileDialog} setUploadResponse={setUploadResponse} setSeverity={setSeverity} setSnackOpen={setSnackOpen} reload={reload} setReload={setReload} />

      <Backdrop open={open} className={classes.backdrop} />
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        hidden={hidden}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={direction}
      >
        <SpeedDialAction
          key="csv"
          icon={<InsertDriveFileIcon />}
          tooltipTitle="CSV"
          tooltipOpen
          onClick={() => toggleFileDialog(true)}
        /> 
        {/* <SpeedDialAction
          key="manual"
          icon={<CreateIcon />}
          tooltipTitle="MANUAL"
          tooltipOpen
          onClick={() => toggleInputDialog(true)}
        /> */}
      </SpeedDial>

      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={snackbarClose} >
        <Alert onClose={snackbarClose} severity={severity}>
          {uploadResponse}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AnalystScreen;
