import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({ 
  formControl: {
      margin: theme.spacing(1),
      fontSize: 10,
      minWidth: 300,
      maxWidth: 350
  }
}));
const FileUploadDialog = ({ open, toggle, setSeverity, setSnackOpen, setUploadResponse, reload, setReload }) => {
  const [file, setFile] = useState();
  const [upType, setUpType] = useState(0);
  const [isLoading, setLoading] = useState(0);
  const [csvObj, getCsvObj] = useState();
  const [regionId, setRegionId] = useState(1);
  const classes = useStyles();
  const getRegionId = async () => {
    let customer = localStorage.getItem("userCust");
    const access_token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: "Bearer " + access_token,
        'Content-Type': 'application/json'
      },
    };

    const response = await fetch(`https://etd-means.aboitizpower.com/means/customerRegion/${customer}`, config);
    const data = (await response.json()).data[0].region;
    setRegionId(data)
  }
  useEffect(() => {
    getRegionId();
  }, [])
  const upNow = async () => {
    setLoading(true);
    const user_role = localStorage.getItem("userRole");
    const status = user_role == 1 ? "PENDING" : "APPROVED";
    var formData = new FormData();
    var url = "https://etd-means.aboitizpower.com/means/upload_dwm"; //test
    const access_token = localStorage.getItem("access_token");
    console.log(process.env.REACT_APP_TP)
    const body = {
      user_email: localStorage.getItem("userEmail"),
      user_upload_type: "csv",
      sub_type: upType,
      status,
      tp: process.env.REACT_APP_TP
    };
    formData.append("submit", JSON.stringify(body));
    formData.append("files", file);
    const config = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
      },
      body: formData,
    };

    const response = await fetch(url, config);
    const data = await response.json();
    if (data.stat == 1) {
      setLoading(false);
      setUploadResponse(data.response);
      setSeverity("success");
      setSnackOpen(true);
      setReload(!reload)
      toggle(false);
      setFile();
    } else {
      setLoading(false);
      setUploadResponse(data.response);
      setSeverity("error");
      setSnackOpen(true);
      setReload(!reload)
      toggle(false);
      setFile();
    }
  };
  const handleFileChange = (file) => {
    const csv = require("csvtojson");
    const reader = new FileReader();

    reader.readAsText(file[0]);
    reader.onload = () => {
      csv({ ignoreEmpty: true })
        .fromString(reader.result)
        .then((jsonObj) => {
          getCsvObj(jsonObj);
          setFile(file[0]);

        })
        .catch((err) => alert(err));
    };
  };
  const handleChange = event => {
    setUpType(event.target.value);
  };
  return (
    <Dialog
      open={open}
      onClose={() => toggle(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle>CSV Upload</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column" 
          alignItems="center"
          justifyContent="space-around"
          hidden={regionId == 1} 
          disabled={regionId == 1}
        > 
          <FormControl required className={classes.formControl}>
          <InputLabel htmlFor="age-native-required">TYPE</InputLabel>
          <Select
            value={upType}
            onChange={handleChange}
          >
            <MenuItem default value="DALN">DALN - Day After</MenuItem>
            <MenuItem value="WALN">WALN</MenuItem>
            <MenuItem value="MALN">MALN</MenuItem>
          </Select>
          </FormControl>
        </Box>
        <Dropzone accept="text/csv" onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)} disabled={upType === 0}>
          {({ getRootProps, getInputProps }) => (
            <Paper {...getRootProps()} style={{ pointerEvents: upType === 0 ? 'none' : 'auto', opacity: upType === 0 ? 0.5 : 1 }}>
              <input {...getInputProps()} disabled={upType === 0} />
              <Box
                display="flex"
                flexDirection="column"
                p={3}
                m={3}
                alignItems="center"
                justifyContent="space-around"
              >
                <CloudUploadIcon />
                <Typography>
                  {file ? file.name : <Typography>Drag 'n' drop CSV File"</Typography>}
                </Typography>
              </Box>
            </Paper>
          )}
        </Dropzone>

        {/* <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Select>
            <MenuItem>1</MenuItem>
            <MenuItem>2</MenuItem>
            <MenuItem>3</MenuItem>
            <MenuItem>4</MenuItem>
          </Select>
        </Box> */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Button enabled={!isLoading} onClick={upNow}>{isLoading ? <CircularProgress size={12}/> : "Upload"}</Button>
        </Box>

      </DialogContent>
    </Dialog>
  );
};

export default FileUploadDialog;
